import React from 'react'
import Policy from './data-request-policy.styles'

const PolicyDetails = () => {
  return (
    <Policy>
      <Policy.title>Data Request Policy</Policy.title>
      <Policy.list>

        <Policy.listChild>
        <Policy.paragraph>
          <b>Privacy Notice.</b> Please see Sitelio
           <Policy.inlineLink to={' https://newfold.com/privacy-center '}>
            Privacy Notice
          </Policy.inlineLink>{' '} for further 
        information on what information Sitelio collects,
        how it is used and when it may be disclosed. Subject to Sitelio Privacy Notice, 
        this Data Request Policy describes the types of data requests that Sitelio may receive 
        and how Sitelio may respond to such requests. 
        </Policy.paragraph>
        </Policy.listChild>
        
        <Policy.listChild>
        <Policy.paragraph>
          <b>Types of Requests.</b>
         Sitelio may receive the following types of requests concerning its Subscribers:
         <Policy.list isAlpha>
         <Policy.listChild>
        <Policy.paragraph>
          <b>Data Requests</b>. A data request is a request for information or documents relating to
         Subscriber accounts in connection with official criminal investigations or other 
         legal proceedings. Except in limited emergency situations (see below), 
         Sitelio requires data requests to be made through formal U.S. legal process and procedures, 
         and such requests will be handled in accordance with applicable law. 
         Examples of acceptable data requests include: 

          <Policy.paragraph>
          <Policy.list isRomanNumeral>
            <Policy.listChild>Subpoenas</Policy.listChild>
            <Policy.listChild>Court Orders</Policy.listChild>
            <Policy.listChild>Search Warrants</Policy.listChild>
            <Policy.listChild>
              Legal process received from outside the United States may require
              a Mutual Legal Assistance Treaty (MLAT) with the United States
            </Policy.listChild>
            <Policy.listChild>
              Other forms of legal process such as Civil Investigative Demands
            </Policy.listChild>
          </Policy.list>
          </Policy.paragraph>
         
        <Policy.listChild>
          <Policy.paragraph>
          <b>Preservation Requests</b>. A preservation request asks Sitelio to preserve Subscriber account records in 
          connection with official criminal investigations or other official legal proceedings. 
          Preservation requests must include the following information: 
          <Policy.list isRomanNumeral>
            <Policy.listChild>Identification of the account(s) at issue (as further described in Section 6 below);</Policy.listChild>
            <Policy.listChild>Identification of the investigating agency and/or specific pending official proceedings 
              (requests must be signed on law enforcement letterhead);</Policy.listChild>
            <Policy.listChild>Assurances that the requesting agency 
              or individual is taking steps to 
              obtain appropriate legal process for access to the data that 
              Sitelio is being asked to preserve; and </Policy.listChild>    
            <Policy.listChild>
            A valid return email address and phone number.
            </Policy.listChild>
          </Policy.list>

          </Policy.paragraph>
        </Policy.listChild>
        <Policy.listChild>
        <Policy.paragraph>
        <b>Emergency Requests</b>. An emergency request is only appropriate in cases involving imminent serious
        bodily harm or death. We respond to emergency requests when we believe in good faith that such
        harm may occur if we do not respond without delay
        </Policy.paragraph>
        </Policy.listChild>

        </Policy.paragraph>
         </Policy.listChild>
         </Policy.list>
         
        </Policy.paragraph>
        </Policy.listChild>
  
        <Policy.listChild>
        <Policy.paragraph>
        <b>Disclosure of Non-Public Domain Registrant Information.</b> As the sponsoring registrar or 
        reseller of certain domain names governed by the Internet Corporation for Assigned Names 
        and Numbers (“ICANN”), Sitelio must permit third parties with a legitimate interest to 
        access masked (non-public) registrant information subject to the data subject’s 
        rights under the General Data Protection Regulation (“GDPR”) or other similar privacy laws
        as applicable. 
        </Policy.paragraph>
        </Policy.listChild>

        <Policy.listChild>
        <Policy.paragraph>
       <b>Notice to Sitelio Subscribers.</b> Sitelio policy is to notify its Subscribers
         of requests for their data unless Sitelio is prohibited from doing so by applicable law or 
         court order.
        </Policy.paragraph>
        </Policy.listChild>

        <Policy.listChild>
        <Policy.paragraph>
       <b>Information to Include in a Request.</b> The following information must be included in a
         request for Subscriber data:
        </Policy.paragraph>
        <Policy.list isAlpha>
          <Policy.listChild>
            <Policy.paragraph>
              First and last name of the Subscriber and email address associated
              with the account; and
            </Policy.paragraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.paragraph>
            Domain name, username and/or IP address associated with the account.
            </Policy.paragraph>
            <br />
            <Policy.paragraph>
              <b>NOTE</b>: A Request that only includes an IP address may be overly broad and insufficient
              for Sitelio to locate a Subscriber account. Please include additional identifiers such domain
              name or username whenever possible.
            </Policy.paragraph>
            <Policy.paragraph>
              Sitelio may not be able to respond to a request without this
              information.
            </Policy.paragraph>
            <Policy.paragraph>
              Additionally, Sitelio reserves the right to request a copy of the
              complaint and any supporting documentation that demonstrates how
              the information requested is related to any pending litigation,
              formal legal proceeding or investigation.
            </Policy.paragraph>
          </Policy.listChild>
        </Policy.list>
        </Policy.listChild>

        <Policy.listChild>
        <Policy.paragraph>
        <b> Sending a Request to Sitelio.</b> All data requests should be sent to:
        </Policy.paragraph>
        <Policy.paragraph>
          Sitelio
          <br />
          Legal Department
          <br />
          5335 Gate Pkwy, 2nd Floor
          <br />
          Jacksonville, FL 32256
          <br />
          Phone: (602) 716-5396 (M-F 7am - 3:30pm MST)
          <br />
          Fax: (904) 659-8905
          <br />
          E-mail: support@sitelio.com
        </Policy.paragraph>
        <Policy.boldParagraph>
          IMPORTANT NOTE: IN THE EVENT YOU SEND US A NOTICE OF ANY KIND VIA
          EMAIL AND DO NOT RECEIVE A RESPONSE FROM SITELIO, PLEASE SUBMIT A DUPLICATE
          COPY VIA MAIL. DUE TO THE VAGARIES OF THE INTERNET AND EMAIL
          COMMUNICATION IN PARTICULAR,INCLUDING THE OCCASIONAL UNINTENDED EFFECTS OF SPAM FILTERS,
          SENDING AN ALTERNATE FORM OF NOTICE WILL HELP ASSURE THAT 
          YOUR NOTICE IS RECEIVED BY SITELIO AND ACTED ON IN A TIMELY MANNER. 
        </Policy.boldParagraph>
        </Policy.listChild>

        <Policy.listChild>
        <Policy.paragraph>
          <b>Fees.</b>Sitelio may seek reimbursement for costs in responding to requests as
          provided by law and may charge additional fees for costs in responding
          to unusual or burdensome requests.
        </Policy.paragraph>
        </Policy.listChild>
        
      <Policy.listChild>
      <Policy.paragraph>
        <b>Available Information.</b> Subject to receiving a valid request,
         Sitelio may provide data as follows:
         <Policy.list isAlpha>
          <Policy.listChild>
            <Policy.boldParagraph>Non-Content. </Policy.boldParagraph>
            <Policy.paragraph>
              Sitelio will produce non-content information such as basic
              Subscriber information which may include the following:
            </Policy.paragraph>
            <Policy.list isRomanNumeral>
              <Policy.listChild>
                <Policy.boldParagraph>Name</Policy.boldParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.boldParagraph>Email addresses</Policy.boldParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.boldParagraph>
                  Date and time of account creation
                </Policy.boldParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.boldParagraph>Billing information</Policy.boldParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.boldParagraph>IP Logs</Policy.boldParagraph>
              </Policy.listChild>
            </Policy.list>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.boldParagraph>Content. </Policy.boldParagraph>
            <Policy.paragraph>
              Sitelio will only produce Subscriber content (such as website files
              and email content) pursuant to a valid search warrant from an
              entity with proper jurisdiction.
            </Policy.paragraph>
          </Policy.listChild>
        </Policy.list>
        </Policy.paragraph>

      </Policy.listChild>    
      </Policy.list>
      
      <Policy.updatedText>
        This file was last modified: October 07, 2022.
      </Policy.updatedText>
    </Policy>
  )
}

export default PolicyDetails
