import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const Policy = styled.div`
  margin: 0 auto;
  padding-bottom: ${theme.space[10]}px;
  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }

  display: flex;
  flex-direction: column;
  color: ${theme.colors.gray};
`

Policy.title = styled.span`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[8]}px 0;
  color: black;
`
Policy.subtitle = styled.span`
  padding: ${theme.space[10]}px 0 ${theme.space[3]}px 0;
  font-size: ${theme.font['xl']};
  text-transform: uppercase;
`

const determineListStyle = (props) => {
  if (props.isRomanNumeral) {
    return 'lower-roman'
  } else if (props.isAlpha) {
    return 'upper-alpha'
  } else {
    return 'decimal'
  }
}

Policy.list = styled.ol`
  list-style-type: ${(props) => determineListStyle(props)};
  padding-left: ${theme.space[8]}px;
`

Policy.titleListChild = styled.li`
  font-size: ${theme.font['xl']};
  padding: ${theme.space[5]}px 0;
  font-weight: 600;
`

Policy.listChild = styled.li`
  font-size: ${theme.font.base};
  padding-bottom: ${theme.space[3]}px;
`

Policy.paragraph = styled.div`
  font-size: ${theme.font.base};
  padding: ${theme.space[2]}px 0;
`

Policy.boldParagraph = styled.span`
  font-weight: 600;
`

Policy.inlineLink = styled(Link)`
  color: ${theme.colors.blue};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.highlightedturqoise};
  }
  :before {
    content: ' ';
  }
`

Policy.updatedText = styled.span`
  margin-top: ${theme.space[4]}px;
  font-size: ${theme.font['sm']};
`

export default Policy
