import React from 'react'
import Layout from '../../components/layout'
import PolicyDetails from '../../components/terms/data-request-policy'
import SEO from '../../components/seo'
import TermsGrid from '../../components/terms/terms-grid'

const DataRequestPolicy = () => {
  return (
    <Layout>
      <SEO title='Data Request Policy' pageType='home' flowType='none' />
      {typeof window !== 'undefined' && (
        <TermsGrid pathname={window.location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default DataRequestPolicy
